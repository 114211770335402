import React, { Component } from 'react';
import './modalContent.css';
import Spline from '@splinetool/react-spline';

export class ModalContent extends Component {
    render() {
        const { title, description, sceneUrl } = this.props;

        return (
            <div className='ModalContainter'>
                <div className='ModalContentMain'>
                    <div className='ModalContentLeft'>
                        <Spline className='splineContent' scene={sceneUrl} />

                    </div>
                    <div className='ModalContentRight'>

                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>

                </div>
            </div>
        );
    }
}

export default ModalContent;
