import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

// Hook personalizado para detectar el tamaño de la pantalla
const useResponsiveScale = () => {
    const [scale, setScale] = useState([1.3, 1.3, 1.3]);

    useEffect(() => {
        const updateScale = () => {
            const width = window.innerWidth;
            if (width > 1024) {
                setScale([1.3, 1.3, 1.3]);
            } else if (width <= 1024 && width > 768) {
                setScale([0.75, 0.75,0.75]);
            } else if (width <= 768 && width > 480) {
                setScale([0.7, 0.7, 0.7]);
            } else {
                setScale([0.6, 0.6, 0.6]);
            }
        };

        window.addEventListener('resize', updateScale);
        updateScale();

        return () => window.removeEventListener('resize', updateScale);
    }, []);

    return scale;
};

const RotatingModel = ({ scrollY, mouse, scale }) => {
    const ref = useRef();
    const { scene } = useGLTF('/models/solarSystem.gltf'); // Asegúrate de que la ruta es correcta

    useFrame(() => {
        if (ref.current) {
            ref.current.rotation.x = mouse.y / 1000;
            ref.current.rotation.y = scrollY / 150 + mouse.x / 1000;
        }
    });

    return (
        <primitive
            object={scene}
            ref={ref}
            position={[0, 0, 0]}
            scale={scale} // Usando la escala pasada como prop
        />
    );
};

const Object3DTwo = ({ scrollY, mouse }) => {
    const modelScale = useResponsiveScale(); // Usando el hook para la escala responsiva

    return (
        <Canvas className="canvasTwo">
            <directionalLight intensity={4} />
            <directionalLight intensity={2} position={[-5, -10, -5]} />
            <RotatingModel scrollY={scrollY} mouse={mouse} scale={modelScale} /> {/* Pasando la escala responsiva */}
        </Canvas>
    );
};

export default Object3DTwo;
