import React, { Component } from 'react';
import VideoComponents from '../../components/serviceComponents/videoComponents/VideoComponents';
import SectionOneComponents from '../../components/serviceComponents/sectionOneComponentes/SectionOneComponents';

export class Services extends Component {
  render() {
    return (
      <div>
        <VideoComponents />
   
      </div>
    );
  }
}

export default Services;
