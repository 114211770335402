import React, { Component } from 'react';
import ImageSequencer from './ImageSequencer';
import './contentFour.css';

export class SectionFour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folderPath: this.getFolderPath(window.innerWidth),
    };
  }

  getFolderPath = (width) => {
    if (width < 768) {
      return '/sectionFour/imagesPhone/';
    // } else if (width < 1200) {
    //   return '/sectionFour/images/tablet/';
    } else {
      return '/sectionFour/images/';
    }
  };

  handleResize = () => {
    const newFolderPath = this.getFolderPath(window.innerWidth);
    if (newFolderPath !== this.state.folderPath) {
      this.setState({ folderPath: newFolderPath });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return (
      <div className='contentFour'>
        <ImageSequencer
          folderPath={this.state.folderPath}
          totalFrames={137}
          frameRate={10}
        />
      </div>
    );
  }
}

export default SectionFour;
