import React, { Component } from 'react';
import './sectionSix.css';
import imgAstro from '../../../images/pexels-mikhail-nilov-7672278.jpg'
import Spline from '@splinetool/react-spline';

export class SectionSix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: ''
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, subject } = this.state;
    // Aquí podrías enviar el correo usando una API o un servicio de backend
    console.log('Form submitted:', { name, email, subject });
    // Reiniciar los campos del formulario
    this.setState({
      name: '',
      email: '',
      subject: ''
    });
  }

  render() {
    const { name, email, subject } = this.state;
    return (
      <div className='contentSixContainer'>
        <div className='contentSix'>
          <h2>Súbete a nuestra nave y potenciemos tu negocio</h2>
          <div className='midContentForm'>
            <form onSubmit={this.handleSubmit} className='contactForm'>
              <div className='formGroup'>
                <label htmlFor='name'>Nombre</label>
                <input
                  type='text'
                  id='name'
                  name='name'
                  value={name}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className='formGroup'>
                <label htmlFor='email'>Correo</label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  value={email}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className='formGroup'>
                <label htmlFor='subject'>Asunto</label>
                <input
                  type='text'
                  id='subject'
                  name='subject'
                  value={subject}
                  onChange={this.handleChange}
                  required
                  className='asuntoInput'
                />
              </div>
              <button type='submit' className='submitButton'>Enviar</button>
            </form>
            <div className='imgFormContainer'>
     
     <Spline className='splineContent' scene={'https://prod.spline.design/jDV4CUA0nESE5lhU/scene.splinecode'} />
         
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionSix;
