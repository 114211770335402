import React, { Component } from 'react';
import './sectionOne.css';
import Object3D from './Object3D';
import logoCTA from '../../../images/sm_blanco puro y naranja plus.png';
import 'animate.css';

export class SectionOne extends Component {
    state = {
        scrollY: 0, // Inicializa en 1 para la posición del scroll
        mouse: { x: 0, y: 0 },
    };

    handleScroll = () => {
        this.setState({ scrollY: window.scrollY });
    };

    handleMouseMove = (event) => {
        this.setState({ mouse: { x: event.clientX, y: event.clientY } });
    };

    componentDidMount() {
        window.scrollTo(0, 1); // Asegurarse de que el scroll inicie en 1
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('mousemove', this.handleMouseMove);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('mousemove', this.handleMouseMove);
    }

    render() {
        return (
            <div className='contentTotal'>
                <Object3D scrollY={this.state.scrollY} mouse={this.state.mouse} />
                <div className='sectionOneMain'>
                    <div className='sectionOneContainer'>
                        <div className='sectionOneContent'>
                            <img className='mainBannerImage animate__fadeIn' src={logoCTA} alt="Logo CTA" />
                            <p>
                                En CTA+, guiamos a las empresas en un viaje de descubrimiento hacia su propósito más auténtico, convirtiendo esa exploración en una fuerza transformadora que impulsa su crecimiento. Este viaje fortalece las conexiones profundas con sus clientes, generando resultados que van más allá de lo económico, creando un impacto sostenible, duradero y lleno de sentido. Nos aventuramos juntos a cruzar fronteras desconocidas, encontrando en cada paso nuevas formas de inspirar y crecer.
                            </p>
                            <h2>BIENVENIDO A LA ACCIÓN</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SectionOne;
