import React, { Component } from 'react'
import './home.css'
import SectionOne from '../../components/homecomponents/sectionOne/SectionOne'
import SectionTwo from '../../components/homecomponents/sectionTwo/SectionTwo'
import SectionThree from '../../components/homecomponents/sectionThree/sectionThree'
import SectionFour from '../../components/homecomponents/sectionFour/SectionFour'
import SectionFive from '../../components/homecomponents/sectionFive/SectionFive'
import SectionSix from '../../components/homecomponents/sectionSix/sectionSix'

export class Home extends Component {
  render() {
    return (
      <div className='homeContainer' >
        
            <SectionOne/>
            <SectionTwo/>
            <SectionThree/>
            <SectionFive/>
            <SectionFour/>
            <SectionSix/>
        




      </div>
    )
  }
}

export default Home