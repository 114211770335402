import React, { Component, useState } from 'react';
import './aboutus.css';
import imgDown from '../../images/Earth from Space.webp';

const AccordionItem = ({ title, content, isOpen, onToggle }) => {
    // Separar el contenido en párrafos
    const paragraphs = content.split('\n').filter(line => line.trim() !== '');

    return (
        <div className="accordion-item">
            <div className="accordion-header" onClick={onToggle}>
                <h3>{title}</h3>
                <span>{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && (
                <div className="accordion-content">
                    {paragraphs.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                    ))}
                </div>
            )}
        </div>
    );
};

export class Aboutus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openIndex: null, // Índice del acordeón abierto
        };
    }

    toggleAccordion = (index) => {
        this.setState((prevState) => ({
            openIndex: prevState.openIndex === index ? null : index,
        }));
    };

    render() {
        const { openIndex } = this.state;

        return (
            <div className='aboutUsContainer'>
                <div className="backgroundImage" />
                <div className="accordion">
                    <AccordionItem
                        title="Exploradores del Propósito: La Historia de CTA+"
                        content={`La historia de CTA+ es un viaje de descubrimiento y transformación. Aunque la empresa fue fundada en 2023, sus raíces se hunden en una aventura que comenzó mucho antes, en 2010, con la creación de una agencia llamada El QUID. En ese entonces, El QUID nació con la misión de aportar soluciones creativas en un mundo cada vez más cambiante, un lugar donde las ideas nacían de la curiosidad y la pasión por descubrir nuevos caminos.
                        
                        Durante más de una década, El QUID navegó los mares del marketing y la tecnología, siempre buscando la chispa que diferenciara a sus clientes en un mercado saturado. Sin embargo, con el paso del tiempo, esa búsqueda constante de innovación y autenticidad nos llevó a una pregunta crucial: ¿por qué hacemos lo que hacemos? Y fue esa pregunta la que impulsó una transformación profunda, una evolución que daría lugar a lo que hoy conocemos como CTA+.

                        En 2023, decidimos reinventarnos y abrazar un propósito aún más grande: ayudar a las marcas a descubrir su propia autenticidad y a transformar esa esencia en una fuerza que conecte profundamente con sus audiencias. Así, CTA+ no es solo una empresa de marketing, es un equipo de exploradores que guía a las empresas en un viaje hacia su verdadero propósito.

                        Cada proyecto, cada cliente, es una nueva aventura. Somos pioneros que no se conforman con lo superficial, sino que buscamos en las profundidades de cada marca, desafiando lo conocido, para encontrar lo que las hace únicas. En CTA+, creemos que las marcas más poderosas no son aquellas que simplemente venden, sino aquellas que inspiran y conectan, porque cuando una marca actúa desde su autenticidad, genera relaciones que perduran y trascienden.

                        Hoy, CTA+ sigue adelante, explorando nuevos territorios en el marketing, la tecnología y la comunicación estratégica, siempre con la brújula de la autenticidad guiando cada paso. Nuestro viaje continúa, y con cada cliente que nos acompaña, descubrimos nuevos horizontes que nos permiten marcar la diferencia.

                        Bienvenidos a CTA+, exploradores de propósito y creadores de conexiones auténticas.
                        `}
                        isOpen={openIndex === 0}
                        onToggle={() => this.toggleAccordion(0)}
                    />
                    <AccordionItem
                        title="Nuestra Metodología: Un Viaje Hacia la Autenticidad"
                        content={`1. Diagnóstico del Propósito Aplicado (DPA) El primer paso de nuestro viaje es el DPA, donde trabajamos de cerca con cada cliente para identificar y alinear su propósito. Aquí exploramos profundamente el "¿Por qué?" detrás de cada marca, utilizando herramientas como el Círculo Dorado de Simon Sinek para definir el Why, How y What de la empresa. Este proceso es crucial para asegurar que todo lo que hagamos esté en sintonía con la esencia auténtica de la marca.

                        2. Estrategia: Crear un Mapa Claro Una vez que entendemos el propósito de la marca, trazamos el camino a seguir. Esta fase incluye la creación de una estrategia de comunicación sólida que guíe todas las acciones y decisiones. Diseñamos un viaje comunicacional que abarca los canales más relevantes para conectar de manera genuina con las audiencias, ya sea a través de contenido, branding, tecnología o inteligencia de negocios. Nuestro enfoque es garantizar que cada acción comunique de manera clara y efectiva el propósito de la marca.
                        
                        3. Desarrollo de Propuestas Creativas La creatividad es uno de los pilares de nuestra metodología. En esta fase, presentamos tres caminos comunicacionales que interpretan la esencia de la marca y la proyectan de diferentes maneras. Estas propuestas no solo están alineadas con el propósito, sino que también están diseñadas para ser emocionantes, disruptivas y auténticas, generando conexiones emocionales y memorables.
                        
                        4. Ejecución y Optimización Continua Una vez que la estrategia ha sido definida, ejecutamos cada paso con precisión y dedicación. Ya sea un desarrollo tecnológico, una campaña de contenido, o una estrategia de inteligencia de negocios, nuestro enfoque es integral y medido. Pero la ejecución no es el fin del viaje; sabemos que la clave del éxito está en la adaptación constante. Por eso, monitorizamos cada acción y ajustamos la estrategia según los resultados y las necesidades emergentes del mercado.
                        
                        5. Medición y Viraje Estratégico En CTA+, creemos que no hay viajes sin aprendizaje. Por ello, la medición es fundamental en nuestra metodología. Evaluamos cada resultado y analizamos el impacto real de las acciones implementadas. Esta fase nos permite ajustar el curso, si es necesario, y asegurar que estamos siempre alineados con el propósito de la marca y las metas de negocio.
                        `}
                        isOpen={openIndex === 1}
                        onToggle={() => this.toggleAccordion(1)}
                    />
                    <AccordionItem
                        title="¿Por qué CTA+?"
                        content={`En CTA+, no solo desarrollamos estrategias, creamos conexiones auténticas que transforman marcas y generan un impacto real. Nos diferenciamos porque no solo hacemos marketing, sino que guiamos a las empresas en un viaje hacia el descubrimiento de su propósito.

                        Autenticidad como motor: Desde el principio, trabajamos para que tu marca se muestre tal como es, sin filtros ni artificios. Esto crea una conexión emocional duradera con tus clientes.

                        Metodología sólida: A través de nuestro DPA, entendemos a fondo quién eres como empresa. Con esta base, trazamos estrategias que no solo te diferencian, sino que te posicionan como líder en tu sector.´
                      
                        
                        Impacto en el ROI de tu empresa
                        Nuestra metodología no solo conecta tu marca con el propósito y su audiencia, sino que también mejora significativamente el retorno sobre la inversión (ROI) a través de cinco pilares clave:
                        Ahorro: Al crear estrategias basadas en una comunicación clara y auténtica, optimizamos el uso de recursos, reduciendo gastos innecesarios y evitando costosos retrabajos.
                        Ganancias: Una marca que conecta emocionalmente con sus consumidores genera mayor lealtad, aumenta las conversiones y, en consecuencia, incrementa las ganancias a largo plazo.
                        Sostenibilidad: Implementamos soluciones que promueven la eficiencia y la optimización de recursos, contribuyendo a un crecimiento sostenible para tu empresa, tanto en términos económicos como medioambientales.
                        Productividad: Nuestro enfoque estructurado agiliza la toma de decisiones y reduce el tiempo dedicado a la implementación de acciones, mejorando la productividad de tus equipos y maximizando resultados.
                        Eficiencia: Al medir y ajustar cada acción estratégicamente, garantizamos que cada inversión en marketing y comunicación se traduzca en resultados óptimos, mejorando la eficiencia general del negocio.
                        En CTA+, creemos que una estrategia auténtica no solo inspira, sino que rentabiliza. Nuestro proceso impulsa el crecimiento de tu empresa mientras optimiza cada recurso invertido.


                        En CTA+, creemos que la autenticidad no solo inspira, sino que también rentabiliza. Al trabajar con nosotros, no solo conectarás con tus audiencias, sino que transformarás esa conexión en resultados tangibles y sostenibles para tu empresa.
                        
                        `}
                        isOpen={openIndex === 2}
                        onToggle={() => this.toggleAccordion(2)}
                    />
                    <AccordionItem
                        title="Clientes"
                        content={`Fiduciaria Bancolombia
                        Prebel
                        AOS
                        MEGO TEAM
                        LendingSpot
                        Estrategia en Ventas
                        Leonardo Calvo
                        Merci Group
                        Matec
                        Solumat
                        SmartSolutions
                        B2B Consulting
                        Contributar
                        Kesher
                        Alux
                        Bezzer
                        Ayudania
                        Yurupary`}
                        isOpen={openIndex === 3}
                        onToggle={() => this.toggleAccordion(3)}
                    />
                </div>
            </div>
        );
    }
}

export default Aboutus;
