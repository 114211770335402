import React, { Component } from 'react';
import './sectionFive.css';
import ModalContent from '../../ModalContent/ModalContent';
import ServiceHomeComponents from '../serviceHomeComponents/ServiceHomeComponents';
import Object3DModular from './object3dModular'

export class sectionFive extends Component {
    render() {
        const services = [
            {
               title: "Contenido",
                text: 'En CTA+, te guiamos en un viaje de comunicación auténtica, conectando tu marca con las audiencias a través de los canales más poderosos y relevantes. Exploramos a fondo cada oportunidad para crear un contenido que no solo llegue a tus consumidores, sino que se quede en su memoria. Nuestro enfoque estratégico transforma cada interacción en una experiencia única, fortaleciendo relaciones y generando una conexión emocional que perdura.',
                modalContent: (
                    <ModalContent
                        title="Contenido"
                        description="El área de contenidos en CTA+ está diseñada para llevar a tu empresa a un nuevo nivel de conexión y relevancia. A través de una estrategia cuidadosamente elaborada, aprovechamos cada canal y formato para maximizar la empatía y recordación de tu marca. Entendemos que el contenido es mucho más que palabras o imágenes; es una oportunidad para contar historias que inspiren, generen confianza y creen un impacto duradero.
                        Nuestro proceso comienza con una comprensión profunda de tu audiencia y tus objetivos. Desde allí, diseñamos estrategias que guían a tu marca en un viaje comunicacional que abarca desde guiones para vídeos y podcasts, hasta contenidos especializados para redes sociales, blogs, y campañas de marketing digital. Cada pieza está pensada para resonar con tus consumidores, reflejando la autenticidad de tu marca y destacándola en un entorno saturado de mensajes.
                        Ya sea creando vídeos que cuenten tu historia en plataformas como YouTube o Vimeo, escribiendo blogs que posicionen tu expertise o diseñando campañas SEM que atraigan a nuevos clientes, nos aseguramos de que cada contenido sea una extensión genuina de tu propósito. Además, nuestras estrategias SEO se enfocan en optimizar tu presencia online, asegurando que no solo estés en el radar de tus consumidores, sino que seas su primera opción.
                        CTA+ no solo crea contenido: guiamos a tu empresa en un proceso de exploración, empatía y conexión emocional que transforma la manera en que te relacionas con tu audiencia. Porque cuando comunicas desde el propósito, el impacto no solo se ve, se siente."
                        sceneUrl='https://prod.spline.design/p0JZRIBfuRgdHFvA/scene.splinecode'
                    />
                )
            },
            {
               title: "Branding",
                text: 'En CTA+, construimos marcas auténticas que inspiran y conectan. A través de un proceso estratégico y creativo, guiamos a las empresas en un viaje de descubrimiento de su identidad, transformando esa autenticidad en una fuerza que conecta emocionalmente con sus audiencias, creando un impacto duradero.',
                modalContent: (
                    <ModalContent
                        title="Branding"
                        description="Descripción sobre la transformación de la marca."
                        sceneUrl='https://prod.spline.design/Lt6XCRkXVj1QvQJd/scene.splinecode'
                    />
                )
            },
            {
              title:"DPA",
                text: 'Descubre el poder de tu propósito con el Diagnóstico del Propósito Aplicado (DPA). En CTA+, te guiamos a consolidar una estrategia auténtica que impulse tu crecimiento, conectando profundamente con tu audiencia y generando un impacto duradero.',
                modalContent: (
                    <ModalContent
                        title="DPA"
                        description="Descripción sobre protección y análisis de datos."
                        sceneUrl='https://prod.spline.design/KPfczsuGz4jMZVYU/scene.splinecode'
                    />
                )
            },
            {
                title:"Administración",
                text: 'En CTA+, te llevamos a un viaje de exploración profunda dentro de tu industria. A través de un enfoque estratégico y tecnológico, descubrimos oportunidades ocultas, vigilamos el entorno competitivo y analizamos tendencias que impulsan decisiones informadas. Nuestro objetivo es ayudarte a navegar un mercado en constante cambio, creando una ventaja competitiva que te permita estar siempre un paso adelante.',
                modalContent: (
                    <ModalContent
                        title="Administración"
                        description="Descripción sobre optimización de procesos administrativos."
                        sceneUrl='https://prod.spline.design/iGEQRoZcIFS0hRUf/scene.splinecode'
                    />
                )
            },
            {
                title: "Tecnología",
                text: 'En CTA+, fusionamos creatividad, tecnología y propósito para crear experiencias digitales que trascienden lo convencional. Desde sitios web de alto rendimiento hasta realidades inmersivas en 3D, VR y AR, te acompañamos en un viaje hacia la innovación, donde cada desarrollo tecnológico no solo resuelve problemas, sino que genera una conexión emocional y memorable con tu audiencia.',
                modalContent: (
                    <ModalContent
                        title="Tecnología"
                        description="Descripción sobre soluciones tecnológicas a medida."
                         sceneUrl='https://prod.spline.design/OgRLoIPeyOK-8SRo/scene.splinecode'
                    />
                )
            },
        ];

        return (
            <div className='contentFiveContainer'>
                <div className='contentFive'>
                    {services.map((service, index) => (
                        <ServiceHomeComponents
                            key={index}
                            title={service.title}
                            text={service.text}
                            isTextAbove={index % 2 === 0}
                            modalContent={service.modalContent}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default sectionFive;