import React, { Component } from 'react';
import './sectionTwo.css';
import Object3DTwo from './Object3DTwo'; // Importa tu componente 3D
import astroImg from '../../../images/astro.png'

export class SectionTwo extends Component {
    state = {
        scrollY: 0,
        mouse: { x: 0, y: 0 },
    };

    handleScroll = () => {
        this.setState({ scrollY: window.scrollY });
    };

    handleMouseMove = (event) => {
        this.setState({ mouse: { x: event.clientX - window.innerWidth / 2, y: event.clientY - window.innerHeight / 2 } });
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('mousemove', this.handleMouseMove);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('mousemove', this.handleMouseMove);
    }

    render() {
        return (
            <div className='contentTwo '>

                <Object3DTwo scrollY={this.state.scrollY} mouse={this.state.mouse} />
                <div className="overlayTwo">
                <div className='sectionTwoMain'>
                    <div className='sectionTwoContainer'>
                        <div className='sectionTwoContent'>
                            <div className='sectionTwoContentRight'>
                                <h2>Profesionales de otra galaxía</h2>
                                <p>
                                Descubre nuevas fronteras para tu marca con un plan comunicacional auténtico y transformador. Exploramos profundamente quién es tu audiencia y seleccionamos los canales más efectivos para que tu mensaje resuene con fuerza. Alineamos tus valores, misión y visión para que no solo conectes con tus clientes, sino que inspires y generes un impacto que perdure.
                                </p>
                                <img src={astroImg}/>
                            </div>

                        </div>
                    </div>
                    
                </div>
             
                  

                 
                </div>




            </div>
        );
    }
}

export default SectionTwo;
