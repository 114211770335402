import React, { Component } from 'react';
import Spline from '@splinetool/react-spline';
import Modal from './Modal';
import './serviceHomeComponents.css';

export class ServiceHomeComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isMobile: window.innerWidth <= 768
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ isMobile: window.innerWidth <= 768 });
    };

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    render() {
        const { title, text, modalContent } = this.props;
        const { isModalOpen, isMobile } = this.state;

        return (
            <div className='serviceComponentContainer'>
                <div className='serviceComponentContent'>
                    {isMobile || this.props.isTextAbove ? (
                        <>
                            <div className='serviceComponentContentLeftFirst'>
                                <h2>{title}</h2>
                                <p>{text}</p>
                            </div>
                            <div className='serviceComponentContentRightFirst'>
                                <button className='button-42' onClick={this.openModal}>EXPLORAR</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='serviceComponentContentLeftSecond'>
                                <button className='button-42' onClick={this.openModal}>EXPLORAR</button>
                            </div>
                            <div className='serviceComponentContentRightSecond'>
                                <h2>{title}</h2>
                                <p>{text}</p>
                            </div>
                        </>
                    )}
                </div>
                <Modal isOpen={isModalOpen} onClose={this.closeModal}>
                    {modalContent}
                </Modal>
            </div>
        );
    }
}

export default ServiceHomeComponents;
