import React, { Component } from 'react';
import './sectionThree.css';
import Object3DThree from './Object3DThree'; // Importa tu componente 3D

export class SectionThree extends Component {
    state = {
        scrollY: 0,
        mouse: { x: 0, y: 0 },
    };

    handleScroll = () => {
        this.setState({ scrollY: window.scrollY });
    };

    handleMouseMove = (event) => {
        this.setState({ mouse: { x: event.clientX - window.innerWidth / 2, y: event.clientY - window.innerHeight / 2 } });
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('mousemove', this.handleMouseMove);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('mousemove', this.handleMouseMove);
    }

    render() {
        return (
            <div className='contentThree'>
        
                <div className="overlayThree"></div> {/* Agrega el overlay aquí */}
                <div className='sectionThreeMain'>
                    <div className='sectionThreeContainer'>
                        <div className='sectionThreeContent'>
                            <div className='sectionThreeContentRight'>
                                <h2>Alcanzar el exito es nuestro proposito</h2>
                                <p>
                                Creamos sitios web dinámicos con WordPress y React, e integramos visualizaciones 3D, AR y VR para experiencias inmersivas. Aumentamos tu visibilidad y posicionamiento con SEM y SEO, atrayendo más tráfico y clientes.
                                </p>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SectionThree;
