import React from 'react';
import { FaWhatsapp, FaFacebookF, FaInstagram, FaLinkedin} from 'react-icons/fa';
import './contactButtons.css';

const ContactButtons = () => {
  return (
    <ul className='iconsContainer'>
      <li>
        <a
          href={`https://wa.me/+573002900350`}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <FaWhatsapp color={'white'} size={30} />
        </a>
      </li>
      <li>
        <a
          href={`https://www.instagram.com/calltoactionplus/`}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <FaInstagram color={'white'} size={30} />
        </a>
      </li>
      <li>
        <a
          href={`https://www.facebook.com/CallToActionPlus`}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <FaFacebookF color={'white'} size={30} />
        </a>
      </li>
      <li>
        <a
          href={`https://www.linkedin.com/company/call-to-action-plus/`}
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-button"
        >
          <FaLinkedin color={'white'} size={30} />
        </a>
      </li>
    </ul>
  );
};

export default ContactButtons;
