import React, { Component } from 'react'
import './footer.css'

export class Footer extends Component {
    render() {
        return (
            <footer>
                <div className='footerContainer'>
                    <p>
                        &copy; 2024 Call to Action Plus. Todos los derechos reservados. | <a href="https://tusitio.com/terminos">Términos de Uso</a> | <a href="https://tusitio.com/politica-de-privacidad">Política de Privacidad</a> | <a href="https://tusitio.com/contacto">Contáctanos</a>
                    </p>
                </div>

            </footer>

        )
    }
}

export default Footer