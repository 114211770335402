import React, { Component } from 'react'

export class Contact extends Component {
  render() {
    return (
      <div>Contact</div>
    )
  }
}

export default Contact