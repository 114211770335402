// src/pages/Services.js
import React, { Component } from 'react';

import video from '../../../videos/Video_Final_DPA.mp4';

export class VideoComponents extends Component {
  videoRef = React.createRef();

  componentDidMount() {
    const videoElement = this.videoRef.current;
    videoElement.volume = 0.3; // Asegura que el volumen esté al máximo
    videoElement.play();

    // Prevenir pausa del video
    videoElement.addEventListener('pause', this.handleVideoPause);

    // Manejar el evento visibilitychange
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  componentWillUnmount() {
    const videoElement = this.videoRef.current;
    videoElement.removeEventListener('pause', this.handleVideoPause);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleVideoPause = () => {
    const videoElement = this.videoRef.current;
    if (!document.hidden) {
      videoElement.play();
    }
  };

  handleVisibilityChange = () => {
    const videoElement = this.videoRef.current;
    if (document.hidden) {
      videoElement.pause();
    } else {
      videoElement.play();
    }
  };

  render() {
    return (
      <div className="servicesContainer">
        <video
          ref={this.videoRef}
          id="backgroundVideo"
          className="backgroundVideo"
          autoPlay
          loop
          playsInline
          controls={false} // Asegura que los controles del video no se muestren
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
}

export default VideoComponents;