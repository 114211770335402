import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import ScrollIndicator from './components/ScrollIndicator/ScrollIndicator';
import Services from './pages/services/Services';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import ContactButtons from './components/contactButtons/contactButtons'
import Footer from './components/footer/Footer';
import About_us from './pages/aboutus/About_us';
import './fonts.css'
import './colorvar.css'
import 'animate.css';


function App() {
  return (
    <Router>
      <div className="app-container">
        <Navbar />
         <ContactButtons/> 
        <ScrollIndicator/> 
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/aboutus" element={<About_us />} />
            <Route path="/projects" element={<div>Projects Page Content</div>} />
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
          
        </div>
    
      <Footer/>

      </div>
      
    </Router>
  );
}

export default App;
