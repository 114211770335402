import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logoCTA from '../../images/sm_blanco puro y naranja plus.png';
import { FaBars } from 'react-icons/fa';
import './Navbar.css';

export class Navbar extends Component {
  render() {
    return (
      <div className='topContentContainer'>
        {/* <button className="hamburger-icon">
          <FaBars />
        </button> */}
        <div className='navImg'> <Link to="/"> <img className='mainBannerImg' src={logoCTA} alt="Logo CTA" /></Link> </div>
     
        {/* <img className='mainBannerImg' src={logoCTA} alt="Logo CTA" /> */}
        <nav className='nav-links'>

          <Link to="/">Inicio</Link>
          <Link to="/aboutus">Exploradores</Link>
          <Link to="/services">Servicios</Link>
          
         
          {/* <Link to="/projects">Projects</Link> */}
          {/* <Link className='contactButton' to="/contact">Contact</Link> */}
        </nav>
      </div>
    );
  }
}

export default Navbar;
