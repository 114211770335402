import React, { Component } from 'react'
import Aboutus from '../../components/AboutUs/Aboutus'
import './About_us.css'

export class About_us extends Component {
  render() {
    return (
      <Aboutus/>
    )
  }
}

export default About_us